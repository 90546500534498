.banner__carousel{
	max-width: 1920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;

	.carousel-inner{
		max-width: 1920px;
		width: 160%;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	.carousel-indicators{
		display: inline-flex;
		flex-direction: column;
		top: 50%;
		transform: translateY(-50%);
		margin-left: 0px;
		margin-right: 0px;
		width: 18px;
		right: 10%;
		left: auto;

		li{
			width: 18px;
			height: 18px;
			border-radius: 50%;
			opacity: 0.7;

			&.active{
				opacity: 1;
			}
		}
	}
}
 .credibilidade{
	padding: 58px 0px 56px 0px;

	.row{
		align-items: center;
	}
 }
 .titulo__credibilidade{
	max-width: 710px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 24px;
	text-align: center;
	color: #130083;
	font-family: 'montserratextrabold';
	font-size: 46px;
	line-height: 46px;

	@include media-breakpoint-down (md) {
		font-size: 30px;
		line-height: 32px;
	}
 }
.segura__credibilidade__nav{
	@include media-breakpoint-up (lg) {
		display: flex;
		align-items: center;
	}

	.nav-tabs{
		position: relative;
		flex-direction: column;
		display: flex;
		padding-left: 30px;
		border-left: 5px solid #F7BA01;
		padding-bottom: 0px;
		border-bottom: none;

		.nav-item{
			padding: 0px;
			margin: 0px;
			border: none;
			margin-bottom: 20px;

			&:last-child{
				margin-bottom: 0px;
			}

			.nav-link{
				width: 60px;
				height: 60px;
				min-width: 60px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #F7BA01;
				border: none;
				transition: all 0.3s linear;
				position: relative;

				&:before{
					height: 5px;
					width: 40px;
					left: -32px;
					content: '';
					position: absolute;
					z-index: -1;
					background-color: #F7BA01;
				}

				&.active{
					background-color: #130083;
					box-shadow: 0px 0px 0px 8px rgba(#130083, 0.6);
				}
			}
		}
		@include media-breakpoint-down (md) {
			padding-left: 0px;
			padding-top: 30px;
			border-top: 5px solid #F7BA01;
			border-left: none;
			flex-direction: row;
			justify-content: space-between;
			padding-left: 10px;
			padding-right: 10px;

			.nav-item{
				.nav-link{
					&:before{
						height: 40px;
						width: 6px;
						top: -32px;
						left: calc(50% - 3px);
					}
				}
			}
		}
	}
	.tab-content{
		@include media-breakpoint-up (lg) {
			padding-left: 26px;
		}
	}
}
.missao__titulo{
	font-family: 'montserratextrabold';
	font-size: 20px;
	line-height: 24px;
	color: #130083;
	margin-bottom: 12px;
}
.produto__destaque{
	padding-bottom: 56px;
	.row{
		align-items: center;

		@include media-breakpoint-down (md) {
			.col-lg-6{
				&:first-child{
					order: 2;
				}
				&:last-child{
					order: 1;
					margin-bottom: 60px;
				}
			}
		}

		.col-lg-6{
			&:first-child{
				position: relative;

				&:before{
					width: 471px;
					height: 471px;
					position: absolute;
					left: -206px;
					content: '';
					background-image: url('../images/detalhe__produtos.png');
					background-repeat: no-repeat;
					background-position: center center;
					background-size: auto;
					top: calc(50% - 245px);
				}
			}
		}
	}
}
.produto__destaque__link{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 210px;
	min-height: 50px;
	border-top: 3px solid #130083;
	border-bottom: 3px solid #130083;
	position: relative;
	font-family: 'montserratbold';
	font-size: 13px;
	line-height: 13px;
	color: #130083;
	transition: all 0.3s linear;

	&:hover{
		background-color: #130083;
		color: #e4e4e4;
		text-decoration: none;
	}

	&:after{
		width: 3px;
		height: 10px;
		top: 0px;
		left: 0px;
		background-color: #130083;
		position: absolute;
		content: '';
	}
	&:before{
		width: 3px;
		height: 10px;
		right: 0px;
		bottom: 0px;
		background-color: #130083;
		position: absolute;
		content: '';
	}
}
.produto__destaque__conteudo{
	max-width: 520px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	background-color: #e4e4e4;
	padding: 24px;
	box-shadow: 0px 0px 0px 12px rgba(#e4e4e4, 0.4);
}
.produto__destaque__imagem{
	position: relative;
	background-color: #D1D1D1;
	box-shadow: 0px 0px 0px 12px rgba(#D1D1D1, 0.4);
	text-align: center;

	&:after{
		width: 40px;
		height: 40px;
		background-color: transparent;
		position: absolute;
		content: '';
		left: -15px;
		top: -15px;
		border-top: 3px solid #130083;
		border-left: 3px solid #130083;
	}
	&:before{
		width: 40px;
		height: 40px;
		background-color: transparent;
		position: absolute;
		content: '';
		right: -15px;
		top: -15px;
		border-top: 3px solid #130083;
		border-right: 3px solid #130083;
	}

	img{
		&:after{
			width: 40px;
			height: 40px;
			background-color: transparent;
			position: absolute;
			content: '';
			left: -15px;
			bottom: -15px;
			border-bottom: 3px solid #130083;
			border-left: 3px solid #130083;
		}
		&:before{
			width: 40px;
			height: 40px;
			background-color: transparent;
			position: absolute;
			content: '';
			right: -15px;
			bottom: -15px;
			border-bottom: 3px solid #130083;
			border-right: 3px solid #130083;
		}
	}
}
.titulo__absoluto{
	font-family: 'montserratblack';
	font-size: 130px;
	line-height: 134px;
	color: #f2f2f2;
	margin-bottom: -40px;
	z-index: -1;
	text-align: right;
}
.produto__destaque__titulo{
	text-align: center;
	font-family: 'montserratextrabold';
	font-size: 20px;
	line-height: 24px;
	color: #000;
	margin-bottom: 10px;
}
.produto__destaque__desc{
	text-align: center;
	margin-bottom: 15px;
	max-width: 374px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.produto__destaque__valor{
	font-family: 'montserratextrabold';
	font-size: 25px;
	line-height: 29px;
	text-align: center;
	color: #000;
	margin-bottom: 24px;
}
.produto__destaque__botao{
	display: flex;
	justify-content:center;
}
.produto__card{
	max-width: 258px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: block;
	position: relative;

	a{
		&:hover{
			text-decoration: none;
		}
	}

	&:hover{
		.produto__card__link__grande{
			.produto__card__botoes{
				opacity: 1;
			}
			.produto__card__imagem{
				&:after{
					opacity: 1;
				}
			}
		}
	}

	.produto__card__link__grande{
		position: relative;

		.produto__card__botoes{
			position: absolute;
			right: 10px;
			bottom: 14px;
			display: flex;
			flex-direction: column;
			padding: 5px;
			background-color: #F7BA01;
			border-radius: 20px;
			z-index: 30;

			.carrinho__card__botao,
			.expandir__botao{
				width: 40px;
				height: 40px;
				background-color: #fff;
				border-radius: 50%;
				color: #130083;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				transition: all 0.3s linear;

				&:hover{
					background-color: #130083;
					color: #fff;
				}

			}
			.carrinho__card__botao{
				margin-bottom: 5px;
			}

			@include media-breakpoint-up (lg) {
				opacity: 0;
			}
		}
	}
	.produto__card__link__grande{
		position: relative;

		.produto__card__imagem{
			position: relative;
			z-index: 20;

			&:after{
				width: 100%;
				height: 100%;
				position: absolute;
				z-index: 10;
				top: 0px;
				left: 0px;
				background-color: rgba(#000, 0.5);
				transition: all 0.3s linear;
				content: '';

				@include media-breakpoint-up (lg) {
					opacity: 0;
				}
			}
		}

		a{
			display: block;
			position: relative;
		}

		img{
			position: relative;
			z-index: 5;
		}


		.produto__card__tags{
			position: absolute;
			top: 10px;
			left: 10px;
			z-index: 15;

			.produto__card__tag{
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #F7BA01;
				width: 54px;
				height: 54px;
				border-radius: 50%;
				margin-bottom: 10px;
				font-family: 'montserratbold';
				font-size: 13px;
				line-height: 13px;
				color: #fff;
			}
		}
	}
}
.titulo__destaques{
	font-family: 'montserratextrabold';
	font-size: 50px;
	line-height: 54px;
	color: #130083;

	@include media-breakpoint-down (md) {
		font-size: 30px;
		line-height: 34px;
	}
}
.categorias___destaque{
	display: flex;
	flex-wrap: wrap;
	flex-grow: 1;
	justify-content: center;

	.categorias__botao{
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		min-height: 38px;
		width: 160px;
		padding: 5px;
		border: none;
		border-top: 3px solid transparent;
		border-bottom: 3px solid transparent;
		font-family: 'montserratbold';
		font-size: 14px;
		line-height: 16px;
		color: #000;
		margin: 5px;
		background-color: transparent;
		transition: all 0.3s linear;

		&:not(.active){
			&:hover{
				border-top-color: #000;
				border-bottom-color: #000;

				&:after,
				&:before{
					background-color: #000;
				}
			}
		}

		&.active{
			color: #130083;
			border-top-color: #130083;
			border-bottom-color: #130083;
			font-family: 'montserratextrabold';
			pointer-events: none;

			&:after,
			&:before{
				background-color: #130083;
			}
		}

		&:after{
			width: 3px;
			height: 10px;
			top: 0px;
			left: 0px;
			background-color: transparent;
			position: absolute;
			content: '';
		}
		&:before{
			width: 3px;
			height: 10px;
			right: 0px;
			bottom: 0px;
			background-color: transparent;
			position: absolute;
			content: '';
		}
	}
}
.row__categorias__home{
	margin-bottom: 30px;
}
.destaques{
	padding-bottom: 60px;
}
.produto__card__link__grande{
	margin-bottom: 14px;
}
.produto__card__valor{
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'montserratextrabold';
	margin-bottom: 5px;

	.produto__valor__total{
		font-size: 16px;
		line-height: 16px;
	}
	.produto__valor__promocao{
		font-size: 12px;
		line-height: 12px;
		text-decoration: line-through;
		margin-right: 5px;
	}
}
.produto__card__titulo{
	font-family: 'montserratextrabold';
	font-size: 14px;
	line-height: 16px;
	color: #343333;
	text-align: center;
	margin-bottom: 2px;
}
.produto__card__descricao{
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 220px;
	width: 100%;
	margin-bottom: 5px;
}
.produto__stars{
	display: flex;
	justify-content: center;
}
