.bg__menu{
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	background-color: rgba(#000, 0.6);
	animation: fadeIn 0.5s linear;
	z-index: 1050;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.login__icone{
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	line-height: 18px;
	position: relative;
	margin-left: 5px;
}
.carrinho__icone{
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	line-height: 18px;
	position: relative;
	margin-left: 5px;
}
.segura__login__topo{
    font-family: 'montserratbold';
	font-size: 13px;
	line-height: 15px;
	color: #130083;
	position: relative;

	a{
		&:hover{
			text-decoration: none;
		}
	}

	.login__icone{
		margin-right: 10px;
	}

    .login__toggler{
        display: flex;
        align-items: center;

        &:hover{
            text-decoration: none;
        }
    }
    .dropdown{
        display: none;
    }

    &.logado{
        .login__toggler{
            display: none;
        }
        .dropdown{
            display: block;
        }
    }
}
.logado__toggler{
	display: flex;
	align-items: center;
	border: none;
	background-color: transparent;
	font-family: 'montserratbold';
	font-size: 13px;
	line-height: 15px;
	color: #130083;
	padding: 5px 0px;

	&:focus{
		outline: none;
	}
}
.encerrar__item{
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .encerrar__link{
        width: 198px;
        max-width: 100%;
        height: 50px;
        background-color: #000;
        color: #fff;
        font-family: 'montserratbold';
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
        transition: all 0.3s linear;
		padding-top: 2px;

		@include media-breakpoint-down(md) {
            height: 28px;
        }

        &:hover{
            background-color: #130083;
            color: #fff;
            text-decoration: none;
        }
    }
}
.logado__nome{
    padding: 28px 20px 22px 20px;
    background-color: #130083;
    color: #fff;
    font-family: 'montserratregular';
    font-size: 13px;
    line-height: 16px;

	@include media-breakpoint-down (md) {
        padding: 12px 6px 10px 6px;
    }
}
.logado__item{
    padding: 0px 10px;
	background-color: #fff;

    .logado__link{
        display: block;
        padding: 22px 10px 20px 10px;
        border-bottom: 1px solid rgba(#000, 0.1);
        font-size: 13px;
        line-height: 15px;
        font-family: 'montserratregular';
        color: #000;

        &:hover{
            font-family: 'montserratbold';
            color: #130083;
			text-decoration: underline;
        }

		@include media-breakpoint-down (md) {
            padding: 10px 6px 10px 6px;
        }
    }
}
.dropdown-menu{
	border: none;
	padding: 0px;
	margin: 0px;
}
.segura__carrinho__topo{
	font-family: 'montserratmedium';
	font-size: 14px;
	line-height: 16px;

	a{
		display: flex;
		align-items: center;

		&:hover{
			text-decoration: none;
		}
	}
}
.carrinho__link__topo{
	position: relative;
	display: inline-block;
	font-family: 'montserratbold';
	font-size: 13px;
	line-height: 15px;
	color: #130083;

	&:hover{
		color: #130083;
	}
}
.sociais__nav{
	display: flex;
	justify-content: center;
	align-items: center;

	a{
		width: 34px;
		height: 34px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #130083;
		color: #d1d1d1;
		border-radius: 50%;
		margin: 0px 2px;
		transition: all 0.3s linear;
		font-size: 20px;
		line-height: 20px;

		&:hover{
			background-color: #F7BA01;
			color: #fff;
			text-decoration: none;
		}
	}
}
.login__texto{
	display: flex;
	flex-direction: column;
}
.carrinho__icone{
	margin-right: 10px;
}
.categorias__listagem{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.btn__toggle__categ{
	padding: 0px;
	font-size: 13px;
	line-height: 15px;
	color: #130083;
	border: none;
	display: flex;
	align-items: center;
	font-family: 'montserratbold';
	margin-right: 20px;
	background-color: transparent;

	.fas{
		margin-right: 10px;
		font-size: 34px;
		line-height: 34px;
	}
}
.topo__categorias{
	background-color: #d1d1d1;
	position: fixed;
	width: 250px;
	height: 100%;
	overflow-y: auto;
	top: 0px;
	left: -250px;
	transition: all 0.5s linear;
	z-index: 1080;

	&.shown{
		left: 0px;
	}

	.categoria__item{
		a{
			display: block;
			padding: 10px;
			border-bottom: 1px solid #130083;
			background-color: #d1d1d1;
			font-family: 'montserratsemibold';
			color: #130083;
			transition: all 0.5s linear;
			font-size: 14px;
			line-height: 18px;

			&:hover{
				color: #130083;
				text-decoration: none;
				background-color: darken(#d1d1d1, 10%);
			}
		}
	}
	.dropdown__controls{
		position: relative;

		button{
			width: 20px;
			height: 20px;
			position: absolute;
			top: calc(50% - 10px);
			right: 10px;
			background-color: #d1d1d1;
			color: #130083;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			line-height: 12px;
			border-radius: 50%;
			border: 1px solid #130083;
			transition: all 0.3s linear;
			padding: 0px;
			padding-top: 2px;

			&[aria-expanded="true"] {
				transform: rotate(90deg);
			}
		}
	}
	.dropmenu{
		list-style: none;
		padding-left: 0px;
		margin-bottom: 0px;

		// li{
		// 	border-bottom: 1px solid #fff;
		// }

		a{
			display: block;
			width: 100%;
			padding: 10px;
			background-color: #130083;
			color: #d1d1d1;
			border-bottom: 1px solid #d1d1d1;

			&:hover{
				color: #d1d1d1;
				text-decoration: none;
				background-color: lighten(#130083, 10%);
			}
		}
	}
}
@include media-breakpoint-up (lg) {
	.mobile__icone{
		display: none !important;
	}
	.topo__mobile{
		display: none;
	}
	.segura__carrinho__topo{
		margin-right: 20px;
	}
	.segura__login__topo{
		margin-right: 20px;
	}
	.logado__menu{
		min-width: 256px;
        max-width: 100%;
        left: calc(50% - 128px) !important;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000, 0.6);
	}
	.logado__toggler{
        position: relative;
        padding: 15px 0px;

        &:after{
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 15px solid #130083;
            content: '';
            position: absolute;
            left: calc(50% - 14px);
            bottom: -3px;
            display: none;
			z-index: 100;
        }

        &[aria-expanded="true"] {
            &:after{
                display: block;
            }
        }
    }
	.topo__main{
		padding: 14px 0px;
		background-color: #d1d1d1;

		.container{
			display: flex;
			align-items: center;

			.main__right{
				display: flex;
				align-items: center;
				flex-grow: 1;
				width: 100%;
				max-width: 740px;

				.main__conteudo{
					display: flex;
					justify-content: flex-end;
					align-items: center;
					width: 100%;
				}
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.topo__main{
		display: none;
	}
	.topo__mobile{
		border-bottom: 3px solid #130083;
		background-color: #d1d1d1;

		.container{
			display: grid;
			grid-template-columns: 1fr minmax(auto, 200px) 1fr;
			padding: 0px;
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
	.segura__sociais__mobile{
		background-color: #130083;
		padding: 10px;

		.sociais__nav{
			a{
				background-color: #d1d1d1;
				color: #130083;

				&:hover{
					background-color: #F7BA01;
					color: #130083;
				}
			}
		}
	}
	.mbl__logo{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.mbl__toggler{
		border: none;
		background-color: transparent;
		font-size: 24px;
		line-height: 24px;
		background-color: transparent;
		color: #130083;

		&:focus{
			outline: none;
		}
	}
	.main__logo{
		padding: 10px;
		text-align: center;
		margin-bottom: 10px;
	}
	.login__toggler,
	.logado__toggler{
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		padding: 10px;
		border-bottom: 2px solid #130083;
		width: 100%;
	}
	.dropdown{
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.logado__menu{
        min-width: 200px;
        max-width: 100%;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000, 0.6);
        left: -3px !important;

        .logado__link{
            font-family: 'montserratbold';
        }
    }
	.login__icone,
	.carrinho__icone{
		margin: 5px;
	}
	.segura__carrinho__topo{
		padding: 15px 0px;
		display: flex;
		justify-content: center;
	}
	.logado__menu{
		border-bottom: 2px solid #e6d9d8;
	}
	.btn__toggle__categ{
		&:before{
			display: none;
		}
	}
	.btn__left__topo{
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.carrinho__link__topo{
			margin-left: 5px;

			.carrinho__icone,
			.carrinho__topo__texto{
				display: none;
			}
		}

		.mobile__login{
			margin-left: 10px;

			.segura__login__topo{
				.login__toggler,
				.logado__toggler{
					padding: 15px 0px;
					border: none;

					&[aria-expanded="true"] {
						&:after{
							display: block;
						}
					}

					&:after{
						border-left: 14px solid transparent;
						border-right: 14px solid transparent;
						border-bottom: 15px solid #130083;
						content: '';
						position: absolute;
						left: -3px !important;
						bottom: 0px;
						display: none;
						z-index: 100;
					}

					.login__texto{
						display: none;
					}
					.login__icone{
						display: none;
					}
				}
			}
		}
	}
	.btn__right__topo{
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.mobile__carrinho{
			margin-right: 10px;

			.mobile__icone{
				position: relative;
				display: flex;
				align-items: center;

				.carrinho__numero__mobile{
					font-size: 12px;
					line-height: 12px;
					padding: 3px 5px;
					background-color: #130083;
					color: #fff;
					font-family: 'montserratextrabold';
					order: 2;
					margin: 0px 3px;
				}
				span{
					font-size: 20px;
					line-height: 20px;
					order: 1;
				}
			}
		}
	}
}
