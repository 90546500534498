.rodape__cima{
	background-color: #eab800;
	color: #fff;
	padding: 24px 0px;

	.row{
		align-items: center;
	}

	.info__card{
		display: flex;
		align-items: center;
		font-size: 13px;
		line-height: 15px;

		.info__card__imagem{
			margin-right: 10px;
		}

		.info__card__titulo{
			font-family: 'montserratextrabold';
			font-size: 15px;
			line-height: 17px;
			margin-bottom: 3px;
		}

		@include media-breakpoint-down (md) {
			flex-direction: column;
			margin-bottom: 20px;
		}
	}
}
.rodape__conteudo{
	padding-top: 40px;
	background-color: #130083;
	color: #fff;
	font-size: 13px;
	line-height: 15px;
}
.duvida__texto,
.duvida__tel{
	font-family: 'montserratextrabold';
}
.duvida__texto{
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 15px;
}
.duvida__tel{
	font-size: 30px;
	line-height: 30px;
	margin-bottom: 15px;
}
.rodape__links,
.rodape__endereco{
	margin-bottom: 15px;
}
.news__titulo{
	font-size: 15px;
	line-height: 18px;
	font-family: 'montserratextrabold';
	margin-bottom: 10px;
}
.news__form{
	display: flex;
	border-radius: 16px;
	background-color: #fff;
	overflow: hidden;
	max-width: 260px;
	width: 100%;

	.news__input{
		width: 100%;
		display: block;
		padding: 12px 16px;
		background-color: #fff;
		border: none;
		font-style: italic;
	}
	.news__btn{
		min-width: 64px;
		background-color: #eab800;
		color: #fff;
		font-family: 'montserratsemibold';
		font-size: 13px;
		line-height: 13px;
		border: none;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
}
.titulo__rodape{
	font-family: 'montserratextrabold';
	font-size: 15px;
	line-height: 19px;
	margin-bottom: 16px;
}
.rodape__nav{
	display: flex;
	flex-direction: column;

	a{
		display: inline-block;
		margin-bottom: 10px;
	}
}
.row__menus__rodape{
	margin-bottom: 70px;
}
.pagamento__rodape{
	display: flex;
	align-items: center;
	font-family: 'montserratextrabold';
	font-size: 13px;
	line-height: 15px;
	text-align: right;

	.pagamento__texto{
		margin-right: 20px;
	}
}
.creditos{
	padding-top: 32px;

	.container{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 18px;
		padding-bottom: 18px;
		border-top: 2px solid rgba(#fff, 0.3);

		@include media-breakpoint-down (md) {
			flex-direction: column;
			align-items: center;
		}
	}
}
.creditos__texto{
	font-size: 12px;
	line-height: 14px;

	span{
		font-family: 'montserratbold';
	}
}
.gv8__box{
	display: flex;
	justify-content:flex-start;
	align-items: center;

	svg{
		margin-left: 5px;
	}

	@include media-breakpoint-down (md) {
		justify-content: center;
	}
}
.sociais__rodape{
	display: flex;
	align-items: center;
	justify-content: center;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		font-size: 18px;
		line-height: 18px;
		color: #130083;
		margin: 0px 2px;
		transition: all 0.3s linear;

		&:hover{
			background-color: #eab800;
			text-decoration: none;
		}
	}
}
@include media-breakpoint-down (md) {
	.rodape__cima{
		.info__card{
			text-align: center;

			.info__card__imagem{
				margin-bottom: 10px;
				margin-right: 0px;
			}
		}
	}
	.rodape__conteudo{
		.col-lg-4{
			margin-bottom: 20px;
		}
	}
	.pagamento__rodape{
		flex-direction: column;
		align-items: center;
		text-align: center;

		.pagamento__texto{
			margin-right: 0px;
			margin-bottom: 10px;
		}
	}
	.creditos__texto{
		text-align: center;
	}
	.gv8__box{
		margin-bottom: 10px;
	}
}
