.interna{
    padding: 90px 0px 72px 0px;
}
.titulo__interna{
    font-family: 'montserratbold';
    font-size: 30px;
    line-height: 34px;
    color: #000;
    margin-bottom: 38px;
}
.minha__conta__nav{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    a{
        display: block;
        padding: 15px 10px;
        font-size: 15px;
        line-height: 15px;
        max-width: 300px;
        border-bottom: 1px solid rgba(#000, 0.4);
        width: 100%;
		text-align: center;
    }
}
.conta__interna{
	.titulo__interna{
		text-align: center;
	}
}
.conteudo__btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: #000;
	color: #fff;
	min-width: 250px;
	height: 50px;
	transition: all 0.3s linear;
	font-family: 'montserratbold';
	font-size: 13px;
	line-height: 13px;
	border: none;
	padding-top: 2px;

	&.voltar__btn{
		min-width: 154px;
	}

	&:focus{
		outline: none;
	}

	&:hover{
		background-color: #130083;
		color: #fff;
		text-decoration: none;
	}
}
.check__custom{

	input{
		position: fixed;
		left: -200vw;

		&:checked{
			~ label{
				&:after{
					opacity: 1;
				}
			}
		}
	}
	label{
		padding-left: 24px;
		position: relative;
		font-size: 14px;
		line-height: 16px;
		font-family: 'montserratsemibold';
		cursor: pointer;

		&:before{
			position: absolute;
			top: calc(50% - 8px);
			left: 0px;
			width: 16px;
			height: 16px;
			border: 2px solid #000;
			content: '';
			border-radius: 50%;
		}
		&:after{
			width: 8px;
			height: 8px;
			position: absolute;
			left: 4px;
			top: calc(50% - 4px);
			background-color: #000;
			content: '';
			opacity: 0;
			border-radius: 50%;
		}
	}
}
.segura__contato__btn{
	padding-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
